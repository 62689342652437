import React from "react";
import Job1 from "../Assets/images/Job1.png";
import Carrier2 from "../Assets/images/Carrier2.png";

const Carrier = () => {
  return (
    <div className="my-11 px-4 md:px-8 lg:px-12 p-3  flex flex-col md:flex-row  md:justify-between md:gap-6 lg:gap-8 items-stretch">
      <div className=" p-4 flex flex-col items-start md:items-start gap-3 md:gap-3 relative w-full md:w-[calc(50%-1rem)] rounded-[20px] bg-[#AAC9F8] flex-grow">
        <h4
          style={{ color: "#192C56" }}
          className="p-4 pb-0 text-[32px]  font-bold leading-[32px] md:leading-[40px] text-center md:text-left"
        >
          Assess Your Job Readiness
        </h4>
        <p
          style={{ color: "#192C56" }}
          className="pl-4 pr-4 text-[18px] font-normal leading-[32px] text-start md:text-left "
        >
          Evaluate your skills, experience, and qualifications with our
          comprehensive Job Readiness Score. Get personalized insights and tips
          to improve your employability and stand out to potential employers.
        </p>
        {/* <button className="m-4 flex justify-center items-center w-[110px] h-[51px] bg-[#F26622] rounded-[6px] text-[14px] md:text-[16px] font-bold leading-[16px] md:leading-[19.49px] text-white mt-4">
          Access Now
        </button> */}
        <div className="relative w-full flex justify-end ">
          <img
            src={Job1}
            alt="Assess Your Job Readiness"
            className="flex items-end w-[320px] h-[240px] md:w-[320px] md:h-[240px]"
          />
        </div>
      </div>

      <div className=" p-4 flex flex-col items-start md:items-start gap-3 md:gap-3 relative w-full md:w-[calc(50%-1rem)] mt-6 md:mt-0 rounded-[20px] bg-[#AAC9F8] flex-grow">
        <h4
          style={{ color: "#192C56" }}
          className="p-4 pb-0 text-start text-[32px] font-bold leading-[32px] md:leading-[40px] md:text-left"
        >
          Ace Your Interviews with Expert Preparation
        </h4>
        <p
          style={{ color: "#192C56" }}
          className="pl-4 pr-4 text-[18px] font-normal leading-[32px] text-left md:text-left "
        >
          Prepare for success with our tailored mock interviews and interview
          coaching. Practice real-world scenarios, receive feedback, and refine
          your responses to boost your confidence and performance in any job
          interview.
        </p>
        {/* <button className="m-4 flex justify-center items-center w-[110px] h-[51px] bg-[#F26622] rounded-[6px] text-[14px] md:text-[16px] font-bold leading-[16px] md:leading-[19.49px] text-white mt-4">
          Know More
        </button> */}
        <div className="relative w-full flex justify-end ">
          <img
            src={Carrier2}
            alt="Ace Your Interviews"
            className="w-[342.36px] h-[199.49px] md:w-[342.36px] md:h-[199.49px]"
          />
        </div>
      </div>
    </div>
  );
};

export default Carrier;
