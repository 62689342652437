import React from "react";
import Marquee from "react-fast-marquee";
import logo from "../Assets/images/Image2.png";

const MarqueeSlider = ({ data, w, h, width }) => {
  return (
    <div className="">
      <section className="flex h-[100px] justify-center items-center">
        <div
          className=" h-[100px]  rounded-2xl"
          style={{ width: width ? width : "100%" }}
        >
          <Marquee
            // gradient
            // pauseOnHover="true"
            speed={30}
            className=" py-3 overflow-hidden grid"
          >
            {data.map((el, id) => {
              return (
                <div
                  key={id}
                  className=" flex px-10 flex-col justify-center items-center h-[100px] w-[200px] mx-3"
                >
                  <img src={el.logo} height={h} width={w} alt="" />
                </div>
              );
            })}
          </Marquee>
        </div>
      </section>
    </div>
  );
};

export default MarqueeSlider;
