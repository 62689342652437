import React from "react";
import success from "../Assets/Real/success1.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import inverted from "../Assets/images/inverted.png";
import p2 from "../Assets/Real/p2.jpeg";
import p4 from "../Assets/Real/p4.jpeg";
import p6 from "../Assets/Real/p6.jpeg";

import p1 from "../Assets/p1.jpeg";
import p3 from "../Assets/p3.jpeg";
import p5 from "../Assets/p5.jpeg";
const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: (
    <div className="slick-prev">
      <svg
        width="15"
        height="27"
        viewBox="0 0 15 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.67568 13L15 3.03333L12.1622 0L0 13L12.1622 26L15 22.9667L5.67568 13Z"
          fill="#192C56"
        />
      </svg>
    </div>
  ),
  nextArrow: (
    <div className="slick-next">
      <svg
        width="15"
        height="27"
        viewBox="0 0 15 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.32432 13.5L0 3.53333L2.83784 0.5L15 13.5L2.83784 26.5L0 23.4667L9.32432 13.5Z"
          fill="#192C56"
        />
      </svg>
    </div>
  ),
};

const Stories = () => {
  const slides = [
    {
      image: success,
      text: "I Used to work in the e-commerce industry. Due to some factors, I ended up losing my job. I was really discouraged. That is when I came across Saksham. I understood the skills that are relevant to me and my profile and found a position for me in the retail industry. Thank you, Saksham",
      author: "Satyaprakash Chaubey",
    },
    {
      image: p1,
      text: "It was a very good experience. I got my offer letter immediately after giving the interview. I am selected in SecureDebt as a Field Collections Officer.",
      author: "Rahul Langadey",
    },
    {
      image: p2,
      text: "I heard DRPPL is organising a job fair in Dharavi from a friend. I registered and applied and was immediately placed. I am very excited that I have my offer letter.",
      author: "Sridhar Patil",
    },
    {
      image: p3,
      text: "I found out about this job fair today, so I registered today, applied today and got placed today. Now I have a job at PayTM",
      author: "Siddesh Namapalli Employer Testimonials",
    },
    {
      image: p4,
      text: "We have a lot of openings Ebix Cash, around 2000 job openings. Being able to bring our openings here in Dharavi is a proud moment for us.",
      author: "Vivek Trivedi, Talent Acquisition Lead at Ebix Cash Global",
    },
    {
      image: p5,
      text: "We had a lot of candidates and offer letters provided. The job fair had great footfall and great candidates",
      author: "Jayesh Yadav, Sr HR Executive at Eureka Outsourcing",
    },
    {
      image: p6,
      text: "We registered a month before the job fair because we were confident that we would  be able to find the right candidates here at Dharavi. Today we are proven right",
      author: "Radhika Kulkarni, Sr HR Executive at Hitachi Cash Management",
    },
    // Add more slides as needed
  ];

  return (
    <div className="w-full mt-20 px-10 md:px-14">
      <Slider {...sliderSettings}>
        {slides.map((slide, index) => (
          <div
            key={index}
            className="relative flex flex-col bg-[#4B6AB0] rounded-[20px] p-4 md:p-6"
          >
            {/* Top-right corner image */}
            <div className="absolute top-2 right-2 p-1 md:ml-6 xs:none md:block">
              <img
                src={inverted}
                alt="Inverted"
                className="h-[50px] w-[80px]"
              />
            </div>
            <div className="flex flex-col md:flex-row items-start">
              <div className="flex-shrink-0 h-[206px] mb-4 md:mb-0">
                <img
                  src={slide.image}
                  alt="Success"
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
              <div className="flex flex-col justify-start md:ml-6 w-full lg:w-[80%] text-center md:text-left ">
                <p className="text-[18px] md:text-[22px] text-[#fff] mb-4leading-[24px] mr-0 md:mr-20 md:leading-[32px] px-2 text-justify">
                  {slide.text}
                </p>
                <p className="text-[20px] md:text-[24px] text-[#fff] font-bold text-justify px-2 ">
                  {slide.author}
                </p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Stories;
