import React from "react";

const Navbar = () => {
  const handleDropdownToggle = () => {
    const dropdown = document.getElementById("dropdownNavbar");
    dropdown.classList.toggle("hidden");
  };

  return (
    <nav className="bg-[#E2F5FF]  w-full z-20 border-b border-gray-200">
      <div className="w-full flex flex-wrap bg-[#E2F5FF] items-center justify-center mx-auto relative">
        <div className="items-center justify-center hidden w-full md:flex md:w-auto h-[68px] md:order-1">
          <ul className="flex bg-[#E2F5FF] flex-col p-4 md:p-0 mt-4 font-medium text-[#192C56] md:space-x-8 rtl:space-x-reverse md:flex-row">
            <li>
              <a
                href="/"
                className="block py-2 px-3 text-[#192C56] "
                aria-current="page"
              >
                Home
              </a>
            </li>
            <li>
              <a href="#" className="block py-2 px-3 text-[#192C56] ">
                Job Seeker
              </a>
            </li>
            <li>
              <a href="#" className="block py-2 px-3 text-[#192C56] ">
                Job Provider
              </a>
            </li>{" "}
            <li>
              <a href="#" className="block py-2 px-3 text-[#192C56] ">
                Events
              </a>
            </li>
            <li>
              <a href="#" className="block py-2 px-3 text-[#192C56] ">
                Job Fair
              </a>
            </li>
            <li>
              <a href="#" className="block py-2 px-3 text-[#192C56] ">
                About Us
              </a>
            </li>
            <li>
              <a href="#" className="block py-2 px-3 text-[#192C56] ">
                Success Stories
              </a>
            </li>
            {/* <li className="relative">
              <button
                onClick={handleDropdownToggle}
                className="flex items-center justify-center w-full py-2 px-3 text-[#192C56]"
              >
                Events
                <svg
                  className="w-2.5 h-2.5 ms-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <div
                id="dropdownNavbar"
                className="absolute hidden font-normal bg-white divide-y rounded-lg shadow w-44  mt-2 right-0 z-30"
              >
                <ul className="py-2 text-sm text-start ">
                  <li>
                    <a
                      href="#"
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Job Provider
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Job Seeker
                    </a>
                  </li>
                </ul>
              </div>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
