import React, { useState } from "react";
import NA from "../Assets/images/N&A1.png";
import NA2 from "../Assets/images/N&A2.png";
import NewsBG from "../Assets/images/NewsBG.png";
import NewsBG1 from "../Assets/images/NewsBG1.png";
import Slider from "react-slick";
import ib from "../Assets/banners/Interview Blog.jpg";
import jfb from "../Assets/banners/job_fair_blog.jpg";
import tsb from "../Assets/banners/tsb.jpg";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  prevArrow: (
    <div className="slick-prev">
      <svg
        width="15"
        height="27"
        viewBox="0 0 15 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.67568 13L15 3.03333L12.1622 0L0 13L12.1622 26L15 22.9667L5.67568 13Z"
          fill="#192C56"
        />
      </svg>
    </div>
  ),
  nextArrow: (
    <div className="slick-next">
      <svg
        width="15"
        height="27"
        viewBox="0 0 15 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.32432 13.5L0 3.53333L2.83784 0.5L15 13.5L2.83784 26.5L0 23.4667L9.32432 13.5Z"
          fill="#192C56"
        />
      </svg>
    </div>
  ),
  // nextArrow: <SampleNextArrow />,
  // prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
const newsdata = [
  {
    img: NA,
    detail1: `अब नौकरी खोजने में सरकारी व्हाट्सएप चैटबॉट करेगा मदद, अपने ही राज्य के जॉब की मिलेगी जानकारी`,
    detail2: `Technology Information, Forecasting and Assessment Council (TIFAC), think-tank under the Department of Science and Technology has launched job portal Saksham that will conduct skills mapping exercises of workers as per requirements of MSMEs.`,
  },

  {
    img: NA2,
    detail1: `TIFAC unveils two new initiatives of Technology —SAKSHAM and a Seaweed Mission`,
    detail2: `Two new initiatives of Technology Information, Forecasting and Assessment Council (TIFAC) —SAKSHAM (Shramik Shakti Manch)- a dynamic job portal for mapping the skills of Shramiks vis-à-vis requirements of MSMEs to directly connect Shramiks with MSMEs and facilitate placement.`,
  },
  {
    img: tsb,
    detail1: `Top Skills Employers Are Looking for in 2024: How to Stay Competitive in the Job Market`,
    detail2: `The job market is evolving rapidly, and for freshers or individuals from low-income backgrounds, staying competitive can feel like a challenge. Whether you're entering the workforce for the first time or looking to switch careers, mastering the right skills can make all the difference. In 2024, employers across industries are focusing on specific skills that can help candidates stand out and succeed. Here's a detailed guide on what skills employers are seeking this year and how you can acquire them to boost your job prospects.`,
  },
  {
    img: ib,
    detail1: `How to Ace Your Next Job Interview: Expert Tips and Common Mistakes to Avoid`,
    detail2: `Preparing for a job interview can be a nerve-wracking experience, especially for freshers or candidates from low-income backgrounds. The competition is fierce, and making a strong impression during the interview is crucial to securing the job you want. However, many job seekers make avoidable mistakes that can hurt their chances. In this guide, we'll walk you through expert tips on how to ace your next job interview and common pitfalls to avoid. By following these strategies, you can enter your interview with confidence and poise.`,
  },
  {
    img: jfb,
    detail1: `The Importance of Job Fairs: How Attending Can Boost Your Career Prospects`,
    detail2: `For freshers and job seekers from low-income communities, landing a job can feel like an uphill battle. The competition is fierce, and navigating the job market can be overwhelming. One of the best ways to increase your chances of success is by attending job fairs, where you can meet multiple employers in one place and explore various career opportunities.
Job fairs not only give you access to job openings but also provide invaluable networking opportunities, exposure to real-world hiring processes, and a chance to sharpen your job search skills. Let’s dive into why job fairs are so important for your career and how they can give you an edge in the competitive job market.`,
  },
];

const News = () => {
  const [more, setMore] = useState(false);
  const [id, setId] = useState(null);
  return (
    <div className="mb-5 mt-14">
      <p className="font-bold text-[40px] mt-5 mb-0">Blogs & Articles</p>

      <div className="w-full flex items-center justify-center">
        <div className="w-[98%] px-10">
          <Slider {...settings}>
            {newsdata.map((d, index) => {
              const isEven = index % 2 === 0;
              const backgroundImage = isEven
                ? `url(${NewsBG})`
                : `url(${NewsBG1})`;

              return (
                <div
                  key={index}
                  style={{
                    backgroundImage: backgroundImage,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    objectFit: "contain",
                    zIndex: 1,
                  }}
                  className="flex flex-col mx-0 bg-gray-100 w-full md:w-[calc(50%-1rem)] rounded-[20px] flex-grow p-3 my-6"
                >
                  <div className="w-full rounded-[20px]">
                    <img
                      src={d.img}
                      className="w-full h-[250px] rounded-[20px]"
                      alt=""
                    />
                  </div>
                  <p
                    style={{ color: "#192C56" }}
                    className="text-[18px] font-normal leading-[32px] text-start md:text-left py-4 pl-4 pr-4 md:text-base"
                  >
                    {d.detail1}
                  </p>
                  <p
                    style={{ color: "#192C56" }}
                    className="text-[18px] text-justify font-normal leading-[32px] text-start md:text-left pl-4 pr-4 md:text-base"
                  >
                    {d.detail2.length > 200 ? (
                      <div>
                        {more && id === index ? (
                          <span>{d.detail2}</span>
                        ) : (
                          <span>
                            {d.detail2.substring(0, 200) + "...." + " "}
                          </span>
                        )}
                        {more && id === index ? (
                          <p
                            className="text-blue-500 underline cursor-pointer"
                            onClick={() => {
                              setMore(false);
                              setId(null);
                            }}
                          >
                            view less
                          </p>
                        ) : (
                          <span
                            className="text-blue-500 underline cursor-pointer"
                            onClick={() => {
                              setMore(true);
                              setId(index);
                            }}
                          >
                            view more
                          </span>
                        )}
                      </div>
                    ) : (
                      <p>d.detail2</p>
                    )}
                  </p>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default News;
