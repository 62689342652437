import React from 'react'
import Nav from '../Components/Nav'
import Searchbar from '../Components/Searchbar'

const Jobs = () => {
  return (
    <div>
      {/* <Nav />
      <Searchbar /> */}
    </div>
  )
}

export default Jobs
