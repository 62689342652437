import React, { useEffect, useState } from "react";
import axios from "axios";
import { Pagination } from "antd";
import "antd/dist/reset.css"; // Import Ant Design styles
import { Tooltip } from "antd";
import job_img from "../Assets/Real/jobs.png";
import Home from "../Components/Home";
import banner from "../Assets/banners/banner.png";
import banner1 from "../Assets/banners/saksham-banner.jpg";
import whatsapp from "../Assets/company_logo/whatsapp.png";
import location from "../Assets/icons/location.png";
import job from "../Assets/icons/job.png";
import { FaArrowUp } from "react-icons/fa";
import Select from "react-select";

const stylesDD = {
  container: (provided) => ({
    ...provided,
    // width: "200px", //window.innerWidth <= 600 ? "50px" :
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    minHeight: "40px",
    border: state.isFocused ? "none" : "none", // Removes border on focus and not focused
    boxShadow: state.isFocused ? "none" : "none",
    fontSize: "14px",
  }),
  indicatorSeparator: () => ({
    display: "none", // Hides the vertical separator
  }),
  option: (base) => ({
    ...base,
    // border: `1px dotted red`,
    height: "100%",
    fontSize: "14px",
  }),
};

const Header = () => {
  const [state, setState] = useState([]);
  const [stateValue, setStateValue] = useState("");
  const [district, setDistrict] = useState([]);
  const handleScroll = () => {
    window.scrollBy({
      top: 400, // Scroll 200px down
      behavior: "smooth", // Smooth scrolling
    });
  };

  const [showButton, setShowButton] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Track scroll position to show/hide button and calculate scroll progress
  const handleScroll1 = () => {
    const scrollTop = document.documentElement.scrollTop;
    const windowHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrollPercent = (scrollTop / windowHeight) * 100;

    setScrollProgress(scrollPercent);

    if (scrollTop > 300) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll1);
    return () => window.removeEventListener("scroll", handleScroll1);
  }, []);
  function stripHtmlTags(html) {
    return html.replace(/<\/?[^>]+(>|$)/g, "");
  }
  const [jobs, setJobs] = useState([]);
  const [search, setSearch] = useState({
    title: "",
    state: "",
    district: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(4); // default page size
  const [total, setTotal] = useState(0);
  const [screen, setScreen] = useState(1);
  const handleSearch = async () => {
    if (search.title !== "" || search.state !== "" || search.district !== "") {
      setScreen(2);
      handleScroll();
    }
    await axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/form/saksham-jobs`, {
        params: {
          title: search.title,
          state: search.state,
          district: search.district.value,
        },
      })
      .then((res) => {
        setJobs(res.data.data);
        setTotal(res.data.data.length);
        setCurrentPage(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Calculate the data to be displayed on the current page
  const currentData = jobs.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const fetchStateList = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
      .then((res) => {
        setState(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchDistricts = () => {
    const params = {
      state: stateValue,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
        // {
        //   state: selectedState,
        // }
      )
      .then((res) => {
        setDistrict(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchStateList();
  }, []);
  useEffect(() => {
    fetchDistricts();
  }, [stateValue]);
  return (
    <div className="w-full">
      <div
        style={{ backgroundImage: `url(${banner})` }}
        className="bg-cover bg-center w-full py-4 min-h-[500px] flex flex-col justify-around"
      >
        <div>
          <p className="font-bold text-[32px] my-4 p-2 text-[#fff]">
            Find What’s <span className="text-[#F26622]">Best for You</span>
          </p>

          {/* <div className="flex justify-center mx-1 my-4">
            <div className="w-full lg:w-[70%] border bg-[#fff]  rounded-full">
              <div className=" flex bg-[#fff] items-center gap-2  w-full px-2 md:px-6 rounded-full">
                <div className="w-[4%] hidden md:block">
                  <svg
                    className="w-4 h-4 "
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <div className="flex bg-[#fff] items-center w-[78%] md:w-[83%] justify-between rounded-full">
                  <div className="max-w-[100px] rounded-full">
                    <input
                      type="search"
                      id="title"
                      className="bg-[#fff] block  py-4 text-md text-black focus:outline-none placeholder-black rounded-full"
                      placeholder="Search Job Title"
                      // required
                      onChange={(e) => {
                        setSearch({ ...search, title: e.target.value });
                      }}
                      value={search.title}
                    />
                  </div>

                  <div>|</div>

                  <div className="w-[30%]">
                    <Select
                      styles={stylesDD}
                      isClearable
                      size="small"
                      options={state}
                      placeholder="Select State"
                      value={
                        stateValue !== ""
                          ? { label: stateValue, value: stateValue }
                          : null
                      }
                      onChange={(e) => {
                        console.log("e11", e);
                        if (e) {
                          setStateValue(e.value);
                          setSearch({ ...search, state: e.value });
                        } else {
                          setStateValue("");
                          setStateValue("");
                          setSearch({ ...search, state: "", district: "" });
                        }
                      }}
                    />
                  </div>
                  <div>|</div>

                  <div className="w-[30%]">
                    <Select
                      styles={stylesDD}
                      isClearable
                      size="small"
                      options={district}
                      placeholder="Select District"
                      value={search.district}
                      onChange={(e) => {
                        if (e) {
                          setSearch({ ...search, district: e });
                        } else {
                          setSearch({ ...search, district: "" });
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="w-[20%] md:w-[12%] flex justify-end">
                  <button
                    //   type="submit"
                    onClick={() => handleSearch()}
                    className="text-white rounded-full end-2.5 bottom-2.5 bg-blue-700 hover:bg-[#f26622] focus:ring-4 focus:outline-none focus:ring-[#F26622] font-medium text-sm px-4 py-2 dark:bg-[#F26622] dark:hover:bg-[#F26622] dark:focus:ring-[#F26622]"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div> */}
          <div className="flex justify-center mx-4 my-4">
            <div className="w-full lg:w-[70%] bg-transparent lg:bg-[#fff] rounded-full below-lg:px-4">
              <div className="flex flex-col md:flex-row items-center gap-4 w-full px-2 md:px-6 rounded-full">
                {/* Search Icon */}
                <div className="w-[5%] hidden md:block">
                  <svg
                    className="w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>

                {/* Search Input */}
                <div className="flex flex-col w-full md:w-[83%] md:flex-row items-center justify-between rounded-full">
                  <div className="w-full md:w-[60%] md:max-w-[150px]">
                    <input
                      type="search"
                      id="title"
                      className="block w-full py-2 md:py-4 px-4 text-md text-black focus:outline-none placeholder-black rounded-full"
                      placeholder="Search Job Title"
                      onChange={(e) => {
                        setSearch({ ...search, title: e.target.value });
                      }}
                      value={search.title}
                    />
                  </div>

                  {/* Divider */}
                  <div className="hidden md:block">|</div>

                  {/* State Select */}
                  <div className="w-full md:w-[30%] mt-2 md:mt-0">
                    <Select
                      styles={{
                        ...stylesDD,
                        control: (base, state) => ({
                          ...base,
                          borderRadius: "9999px", // Fully rounded dropdown
                          border: state.isFocused ? "1px solid #ccc" : "none", // Border for small screens when focused
                          boxShadow: state.isFocused ? null : null,
                          "@media (min-width: 1024px)": {
                            // For large screens (≥1024px)
                            border: "none", // No border for large screens
                          },
                        }),
                      }}
                      isClearable
                      size="small"
                      options={state}
                      placeholder="Select State"
                      value={
                        stateValue !== ""
                          ? { label: stateValue, value: stateValue }
                          : null
                      }
                      onChange={(e) => {
                        if (e) {
                          setStateValue(e.value);
                          setSearch({ ...search, state: e.value });
                        } else {
                          setStateValue("");
                          setSearch({ ...search, state: "", district: "" });
                        }
                      }}
                    />
                  </div>

                  {/* Divider */}
                  <div className="hidden md:block">|</div>

                  {/* District Select */}
                  <div className="w-full md:w-[30%] mt-2 md:mt-0">
                    <Select
                      styles={{
                        ...stylesDD,
                        control: (base, state) => ({
                          ...base,
                          borderRadius: "9999px", // Fully rounded dropdown
                          border: state.isFocused ? "1px solid #ccc" : "none", // Border for small screens when focused
                          boxShadow: state.isFocused ? null : null,
                          "@media (min-width: 1024px)": {
                            // For large screens (≥1024px)
                            border: "none", // No border for large screens
                          },
                        }),
                      }}
                      isClearable
                      size="small"
                      options={district}
                      placeholder="Select District"
                      value={search.district}
                      onChange={(e) => {
                        if (e) {
                          setSearch({ ...search, district: e });
                        } else {
                          setSearch({ ...search, district: "" });
                        }
                      }}
                    />
                  </div>
                </div>

                {/* Search Button */}
                <div className="w-full md:w-[12%] flex justify-center lg:justify-end mt-4 md:mt-0">
                  <button
                    onClick={() => handleSearch()}
                    className="text-white rounded-full bg-[#f26622] hover:bg-[#f26622] focus:ring-4 focus:outline-none focus:ring-[#F26622] font-medium text-sm px-4 py-2 dark:bg-[#F26622] dark:hover:bg-[#F26622] dark:focus:ring-[#F26622]"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" flex justify-center">
          <p className="w-[75%] font-large text-[32px] my-4 p-2 text-[#fff]">
            The world's first AI-powered platform for livelihood enhancement of
            low-income workforce/citizens
          </p>
        </div>
        <div></div>
      </div>
      <div class="flex justify-end items-center my-8">
        <a
          href="https://api.whatsapp.com/send?phone=917700957245&text=Hi"
          target="_blank"
          rel="noreferrer"
          class="fixed bottom-[65px] sm:bottom-[80px] z-10 right-[12px] sm:right-[20px]  sm:bg-[#007119] py-2 px-3 sm:rounded-md sm:shadow-lg sm:hover:bg-green-700 transition duration-300 gap-2"
        >
          <div className="flex items-center justify-center gap-2">
            <img src={whatsapp} alt="" />
            <div className="hidden sm:block">
              <p className="text-[16px]  text-white font-bold mb-0 ">
                Chat With Us
              </p>
            </div>
          </div>
        </a>
      </div>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: `${scrollProgress}%`,
          height: "5px",
          backgroundColor: "green",
          zIndex: 99,
        }}
      />

      {/* Scroll-to-top button */}
      {showButton && (
        <button
          onClick={scrollToTop}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#fff",
            color: "#0000009c",
            border: "2px solid 0000009c",

            borderRadius: "50%",
            padding: "10px",
            cursor: "pointer",
            zIndex: 1000,
          }}
        >
          <FaArrowUp size={24} />
        </button>
      )}

      {screen === 1 ? (
        <Home />
      ) : (
        <div className=" mb-2">
          {/* {search.title !== "" ||
          search.state !== "" ||
          search.district !== "" ? ( */}
          <div className="m-2 px-4">
            <div className="flex gap-4 items-center mx-4 mb-4">
              <div
                className="cursor-pointer"
                onClick={() => {
                  setScreen(1);
                  setSearch({
                    title: "",
                    state: "",
                    district: "",
                  });
                }}
              >
                <svg
                  width="15"
                  height="27"
                  viewBox="0 0 15 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.67568 13.5L15 3.53333L12.1622 0.5L0 13.5L12.1622 26.5L15 23.4667L5.67568 13.5Z"
                    fill="#192C56"
                  />
                </svg>
              </div>

              <p className="text-start mb-0 font-semibold text-xl">
                {total} Jobs available
              </p>
            </div>
            <div className=" shadow">
              {jobs.length > 0 ? (
                <div className=" flex justify-center flex-col gap-4">
                  <div className="grid xs:grid-cols-1  gap-4">
                    {currentData.map((d) => (
                      <div className="w-full bg-[#F6F6F6] border border-gray-200 rounded-lg shadow grid gap-8 m-2">
                        {/* <div className="p-4">
                          <div className=" flex justify-center w-full ">
                            <img
                              src={d.image ? d.image : job_img}
                              alt=""
                              className="h-20 w-20 rounded-t-lg object-cover"
                            />
                          </div>

                          <div className="mt-2">
                            <div className="min-h-[40px]">
                              <h5 className="mb-2 text-2xl font-bold tracking-tight text-black-800 text-center">
                                {d.job_title}
                              </h5>
                            </div>
                            <div className="h-[75px]">
                              {d?.jobdesc?.length > 100 ? (
                                <Tooltip title={d.jobdesc}>
                                  <p className="my-2 font-normal text-black-700">
                                    {d.jobdesc.substring(0, 100) + "..."}
                                  </p>
                                </Tooltip>
                              ) : (
                                <p className="my-2 font-normal text-black-700">
                                  {d.jobdesc}
                                </p>
                              )}
                            </div>

                            <a target="_blank" href={d.link} rel="noreferrer">
                              <button className="w-[177px] h-[51px] mb-3 text-white p-[16px_8px] gap-[10px] rounded-[6px] bg-[#192C56]">
                                Know More
                              </button>
                            </a>
                          </div>
                        </div> */}
                        <div className="flex flex-col md:flex-row items-center gap-7 p-4">
                          <div className="min-h-40 w-[180px]bg-[#F6F6F6] shadow-md rounded-md">
                            <img
                              src={d.org_logo}
                              alt=""
                              className="rounded min-h-40 w-[180px] bg-[#F6F6F6]"
                            />
                          </div>
                          <div className="md:w-[70%] flex flex-col lg:flex-row gap-4">
                            <div className="text-left flex flex-col gap-[10px] xs:w-[100%] lg:w-[40%]">
                              <p className="mb-0 text-2xl text-black-800 font-bold">
                                {d.designation}
                              </p>
                              <p className=" mb-0 font-semibold text-black-700 text-lg">
                                {d.org_name ? d.org_name : "-"}
                              </p>
                              <div className="flex gap-2 items-center">
                                <img
                                  src={location}
                                  alt=""
                                  className="h-[18px] w-[18px]"
                                />
                                <p className="mb-0">
                                  {d?.district ? d?.district : "-"}
                                </p>
                              </div>
                              <div className="flex gap-2 items-center">
                                <img
                                  src={job}
                                  alt=""
                                  className="h-[18px] w-[18px]"
                                />
                                <p className="mb-0">
                                  {d.experience
                                    ? d.experience + " " + "years"
                                    : "-"}
                                </p>
                              </div>
                              {/* <button className="w-[177px] h-[51px] mb-3 text-white p-[16px_8px] gap-[10px] rounded-[6px]  bg-[#192C56]">
                          Know More
                        </button> */}
                            </div>
                            <div className="lg:border-l-2 border-[rgb(0 0 0 / 42%)] lg:pl-3 xs:w-[100%] lg:w-[40%]">
                              <p className="text-base text-black text-justify ">
                                {d.jobdesc ? stripHtmlTags(d.jobdesc) : "NA"}
                              </p>
                            </div>
                          </div>
                          <div>
                            <a href={d.link} target="_blank">
                              <button className="w-[177px] h-[51px] mb-3 text-white p-[16px_8px] gap-[10px] rounded-[6px] bg-[#192C56]">
                                Apply
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="w-full flex justify-end ">
                    <Pagination
                      current={currentPage}
                      pageSize={pageSize}
                      total={jobs.length}
                      onChange={handlePageChange}
                      pageSizeOptions={["4", "10", "20", "50", "100"]}
                      showSizeChanger
                    />
                  </div>
                </div>
              ) : (
                <h1>No Search Found!</h1>
              )}
            </div>
          </div>
          {/* ) : null} */}
        </div>
      )}
    </div>
  );
};

export default Header;
