import React, { useEffect } from "react";
import myImage from "../Assets/images/Image1.png";
import logo from "../Assets/images/Image2.png";
import logo2 from "../Assets/images/Image3.png";
import logo3 from "../Assets/images/Image4.png";
import logo4 from "../Assets/images/Image5.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCaretDown } from "@fortawesome/free-solid-svg-icons";

const Nav = () => {
  const handleDropdownToggle2 = () => {
    const dropdown = document.getElementById("dropdownNavbar2");
    dropdown.classList.toggle("hidden");
  };

  const handleDropdownClose2 = () => {
    const dropdown = document.getElementById("dropdownNavbar2");
    setTimeout(() => {
      dropdown.classList.add("hidden");
    }, 5000);
  };
  return (
    <div className="relative flex items-center py-2 p-4 space-x-4 w-full flex-col md:flex-row gap-2">
      <div className="flex gap-3 justify-around sm:justify-start w-full sm:w-[auto]  items-center">
        <div className="w-[74px] h-[62px] bg-gray-200">
          <img src={myImage} alt="" className="w-full h-full object-cover" />
        </div>
        <div className=" flex gap-2 items-center flex-col justify-center">
          <img
            src={logo}
            alt=""
            className="w-full h-full w-[84px] h-[35px] object-cover"
          />
          <p className="text-[#192c56] font-semibold text-[13px] mb-0">
            Nodal Agency
          </p>
        </div>
      </div>

      <div className="flex-grow flex justify-center">
        <ul className="flex items-center space-x-4 max-w-[457px]">
          <li className="text-left font-bold text-[15px] leading-[18.15px] sm:text-[13px] sm:leading-[16px]">
            A Project of
          </li>
          <li className="w-[62.39px] h-[69.11px]">
            <img src={logo2} alt="" className="w-full h-full object-cover" />
          </li>
          <li className="w-[118.2px] h-[66px]">
            <img src={logo3} alt="" className="w-full h-full object-cover" />
          </li>
        </ul>
      </div>

      {/* Button and Icon */}
      <div className="flex-shrink-0 flex items-center space-x-2 relative">
        {" "}
        {/* Ensure this has relative positioning */}
        <a
          href="https://seeker.globalsapio.com/jobs?cpid=&id=srs&source=&jobid=null&orgid=null"
          target="_blank"
        >
          <button
            className="
            w-[210px] h-[51px]
            p-[16px] pl-[48px] pr-[48px]
            rounded-[6px]
            flex items-center justify-center
            bg-[#192C56] text-white
            text-sm sm:w-[180px] sm:h-[45px] sm:pl-[32px] sm:pr-[32px] lg:w-[210px] lg:h-[51px] lg:pl-[48px] lg:pr-[48px]"
          >
            Login/Register
          </button>
        </a>
        {/* <button onClick={handleDropdownToggle2} className="relative">
          {" "}
          <FontAwesomeIcon
            icon={faSquareCaretDown}
            className="text-blue ml-2 text-2xl sm:text-xl lg:text-3xl"
          />
        </button> */}
        <button
          onMouseEnter={handleDropdownToggle2}
          onMouseLeave={handleDropdownClose2}
          className="flex items-center justify-center w-full py-2 px-3 text-[#192C56]"
        >
          For Employers
          <svg
            className="w-2.5 h-2.5 ms-2.5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>
        <div
          id="dropdownNavbar2"
          className="absolute hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600 mt-2 right-0 top-full z-30"
        >
          <ul className="py-2 text-sm text-start text-gray-700 dark:text-gray-200">
            <li>
              <a
                href="https://jobprovider.sakshamtifac.org/"
                target="blank"
                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              >
                Registration
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Nav;
