import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Homepage from "./Pages/Homepage";
import Jobs from "./Pages/Jobs";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Homepage />}></Route>
        <Route path="/jobs" element={<Jobs />}></Route>
      </Routes>
    </div>
  );
}

export default App;
