import React from "react";
import Home from "../Components/Home";
import Footer from "./Footer";
import Nav from "../Components/Nav";
import Navbar from "./Navbar";
import Searchbar from "../Components/Searchbar";
import Header from "./Header";

const Homepage = () => {
  return (
    <div className="max-w-full">
      <Nav />
      <Navbar />
      <Header />
      <Footer />
    </div>
  );
};

export default Homepage;
